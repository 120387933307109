<template>
    <div class="ifmf_wrap pa0 ma0">
        <div class="item_modal_filter_wrap ma0">
            <div class="search_item_wrap">
                <div class="filter_category_box">
                    <el-select id="category" v-model="filterInputOption" class="my_filter select"
                               popper-class="category_select"
                               :popper-append-to-body="false">
                        <el-option :value="'do'" :label="$t('domain')"></el-option>
                        <el-option :value="'pa'" :label="$t('url')"></el-option>
                    </el-select>
                </div>
                <div class="search_input">
                    <el-input :placeholder="$t('st')" v-model="filterInput"
                              @keypress.enter.native="setFilterFunc(filterInputOption,filterInput)"/>
                    <button class="search_btn" @click="setFilterFunc(filterInputOption,filterInput)">
                        <span>{{$t('search')}}</span>
                        <img src="@/assets/image/main/search_btn.png">
                    </button>
                </div>
                <button class="url_btn" @click="movePage(`/detection/${detectionKey}/url`)">
                    <span>{{ $t('ignore_url') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import detectionUtil from "@/components/detection/detectionUtil";

const {filterConfig} = detectionUtil;
export default {
    name: "DetectionFilterHistoryLayout",
    mixins: [],
    components: {

    },
    inject: ['setFilter'],
    provide() {
        return{

        }
    },
    props: {
        filterData: {default: () => {}},
    },
    data() {
        return{
            filterInputOption: 'do',
            filterInput: '',
            inputDate: '',
            detectionKey: Number(this.$route.params.d_idx),
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        openModal(name){
            this.$modal.show(name);
        },
        searchSubmit() {
            // 검색 입력 후 ,
            this.searchCheck = true;
            this.$router.push('/search');
        },
        movePage(url) {
            this.$router.push(url);
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
